import { Suspense, lazy, useState } from 'react';
import { wrapUseRoutes, ErrorBoundary } from '@sentry/react';
import { PropTypes } from 'prop-types';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
import { LoadingScreen } from '@boletia/blt-ui';
import RequireAuth from './require-auth';
import RequireRol from './require-rol';
import RequireSuperAdmin from './require-superadmin';
import AdminLayout from '../layouts/admin';
import EventLayout from '../layouts/event';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MyAccountPage from '../modules/events/pages/MyAccount';
import { PATH_PP, PATH_TC, RESEND_CANCEL } from './paths';
import CheckinLayout from '../layouts/check-in';
import RequireParentEvent from './require-parent-event';
import useTester from '../hooks/useTester';
import RequireTester from './require-tester';
import RequireV2 from './require-v2';
import ErrorFallback from '../pages/ErrorFallback';
import RequireAcceptedContract from './require-accepted-contract';

const useSentryRoutes = wrapUseRoutes(useRoutes);

const LoadableComponent = ({ Component, ...props }) => {
  // retryKey is used to force re-mounting of the lazy component when a retry is needed.
  const [retryKey, setRetryKey] = useState(0);

  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <ErrorBoundary
        resetKeys={[retryKey]}
        fallback={({ error, resetError }) => (
          <ErrorFallback
            error={error}
            resetError={() => {
              setRetryKey((prev) => prev + 1);
              resetError();
            }}
          />
        )}
      >
        <Component key={retryKey} {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

LoadableComponent.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

const retryImport = (importFunction) => {
  const retries = 3;
  const delay = 500;

  return new Promise((resolve, reject) => {
    // Recursive attempt function that decreases the retry count on each failure.
    const attempt = (n) => {
      importFunction()
        .then(resolve)
        .catch((error) => {
          if (n > 1) {
            setTimeout(() => attempt(n - 1), delay);
          } else {
            reject(error);
          }
        });
    };

    attempt(retries);
  });
};

const Loadable = (importFunc) => {
  // Create a lazy component that uses retryImport to handle the dynamic import.
  const LazyComponent = lazy(() => retryImport(importFunc));

  return (props) => <LoadableComponent Component={LazyComponent} {...props} />;
};

export default function Router() {
  const { isTester: showOldStatistics } = useTester({
    testerType: 'showOldStatistics',
  });

  return useSentryRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'register',
          element: <Register />,
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-weak-password', element: <ResetWeakPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        {
          path: 'change-password',
          element: (
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          ),
        },
        { path: 'verify', element: <VerifyEmailPage /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/events" replace />,
    },
    {
      path: '/register-incode',
      element: (
        <RequireAuth validateChangePass>
          <RegisterWithIncode />
        </RequireAuth>
      ),
    },
    {
      path: '/dashboard',
      element: (
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <RequireAuth validateChangePass>
            <AdminLayout />
          </RequireAuth>
        </ErrorBoundary>
      ),
      children: [
        { element: <Navigate to="/dashboard/events" replace />, index: true },
        { path: 'events', element: <EventsPage /> },
        { path: 'event-create', element: <EventCreate /> },
        { path: 'my-account', element: <MyAccountPage /> },
        {
          path: 'billboards',
          element: <Billboards />,
        },
        {
          path: 'billboards-create/:billboard_type',
          element: <BillboardCreate />,
        },
        {
          path: 'billboards-edit/:billboard',
          element: (
            <RequireRol rol="owner">
              <Billboard />
            </RequireRol>
          ),
        },
        {
          path: 'campaigns',
          element: (
            <RequireAcceptedContract testerType="showActiveCampaigns">
              <Outlet />
            </RequireAcceptedContract>
          ),
          children: [
            { path: '', element: <Campaigns /> },
            { path: 'create', element: <CreateCampaign /> },
            { path: ':campaign/create-ad', element: <CreateAd /> },
            { path: 'add-balance', element: <AddBalance /> },
            { path: ':campaign/edit', element: <EditCampaign /> },
            { path: ':campaign/edit-ad/:ad', element: <EditAd /> },
            { path: '*', element: <Navigate to="" replace /> },
          ],
        },
        { path: 'team', element: <MyTeam /> },
        { path: 'team-edit/:user', element: <MyTeaEdit /> },
        { path: 'team-add', element: <MyTeamAdd /> },
        { path: 'statistics', element: <GlobalStatistics /> },
        {
          path: 'unarchive',
          element: (
            <RequireSuperAdmin>
              <Unarchive />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'search',
          element: (
            <RequireSuperAdmin>
              <SuperAdminSearch />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'delete-accounts',
          element: (
            <RequireSuperAdmin>
              <DeleteAccounts />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'change-ownership',
          element: (
            <RequireSuperAdmin>
              <ChangeOwnership />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'set-password',
          element: (
            <RequireSuperAdmin>
              <SetPassword />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'bines',
          element: (
            <RequireSuperAdmin>
              <RequireTester testerType="showPaymentMethods">
                <Bines />
              </RequireTester>
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'bines/:bank',
          element: (
            <RequireSuperAdmin>
              <Bank />
            </RequireSuperAdmin>
          ),
        },
        {
          path: 'wallet',
          element: (
            <RequireTester testerType="showWallet">
              <TicketAccess />
            </RequireTester>
          ),
        },
        {
          path: 'active-campaigns',
          element: (
            <RequireTester testerType="showActiveCampaigns">
              <Outlet />
            </RequireTester>
          ),
          children: [
            { path: '', element: <ActiveCampaigns /> },
            {
              path: 'campaign-review/:campaign/:user',
              element: <CampaignReview />,
            },
          ],
        },
        {
          path: 'check-in',
          children: [
            {
              element: (
                <Navigate to="/dashboard/check-in/events-control" replace />
              ),
              index: true,
            },
            {
              path: 'events-control',
              element: <CheckinEvents />,
            },
            {
              path: '*',
              element: (
                <Navigate to="/dashboard/check-in/events-control" replace />
              ),
            },
          ],
        },
      ],
    },
    {
      path: '/event/:event',
      element: (
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <RequireAuth validateChangePass>
            <RequireRol rol="analyst">
              <EventLayout />
            </RequireRol>
          </RequireAuth>
        </ErrorBoundary>
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <EventDashboardLayout />
            </RequireRol>
          ),
          children: [
            {
              element: <Navigate to="sales" replace />,
              index: true,
            },
            {
              path: 'sales',
              element: (
                <RequireRol rol="analyst" exclude="editor">
                  <Sales />
                </RequireRol>
              ),
            },
            {
              path: 'clients',
              element: (
                <RequireRol rol="analyst" exclude="editor">
                  <Clients />
                </RequireRol>
              ),
            },
            {
              path: 'funnel',
              element: (
                <RequireRol rol="analyst" exclude="editor">
                  <Funnel />
                </RequireRol>
              ),
            },
          ],
        },
        {
          path: 'dashboard/general',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <Dashboard />
            </RequireRol>
          ),
        },
        {
          path: 'stats',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <Statistics2 />
            </RequireRol>
          ),
        },
        {
          path: 'statistic-old',
          element: <>{showOldStatistics && <Statistics />}</>,
        },
        {
          path: 'conf/edit',
          element: (
            <RequireRol rol="analyst">
              <EventEdit />
            </RequireRol>
          ),
        },
        {
          path: 'conf/map',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Map />
            </RequireRol>
          ),
        },
        {
          path: 'conf/map/selection',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <TicketsMapSelection />
            </RequireRol>
          ),
        },
        {
          path: 'conf/tickets',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Tickets />
            </RequireRol>
          ),
        },
        {
          path: 'conf/tickets/create',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <TicketCreate />
            </RequireRol>
          ),
        },
        {
          path: 'conf/tickets/edit/:ticket',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <TicketEdit />
            </RequireRol>
          ),
        },
        {
          path: 'conf/holds',
          element: (
            <RequireV2>
              <RequireRol rol="partner" exclude="analyst">
                <Holds />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/create',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <HoldsCreate />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/view/:hold',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <HoldsView />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/edit/:hold',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <HoldsEdit />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/:hold/create-batch',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <BatchCreate />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/holds/:hold/batch/:batch',
          element: (
            <RequireV2>
              <RequireRol rol="editor" exclude="analyst">
                <Batch />
              </RequireRol>
            </RequireV2>
          ),
        },
        {
          path: 'conf/payments',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Payments />
            </RequireRol>
          ),
        },
        {
          path: 'conf/surveys',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Surveys />
            </RequireRol>
          ),
        },
        {
          path: 'rsvp/dashboard',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <RsvpDashboard />
            </RequireRol>
          ),
        },
        {
          path: 'rsvp/links/:link',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <RsvpLinks />
            </RequireRol>
          ),
        },
        {
          path: 'services/invitations',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Invitations />
            </RequireRol>
          ),
        },
        {
          path: 'services/bines',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Maintenance />
            </RequireRol>
          ),
        },
        {
          path: 'services/promotions',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Promotions />
            </RequireRol>
          ),
        },
        {
          path: 'services/services',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Services />
            </RequireRol>
          ),
        },
        {
          path: 'services/checkin',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Checkin />
            </RequireRol>
          ),
        },
        {
          path: 'services/rspv',
          element: (
            <RequireRol rol="editor" exclude="analyst">
              <Rspv />
            </RequireRol>
          ),
        },
        {
          path: 'services/duplicate',
          element: (
            <RequireRol rol="owner">
              <Duplicate />
            </RequireRol>
          ),
        },
        {
          path: 'reservations/totals',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <TotalReservation />
            </RequireRol>
          ),
        },
        {
          path: 'reservations/totals/:booking',
          element: (
            <RequireRol rol="analyst" exclude="editor">
              <Booking />
            </RequireRol>
          ),
        },
        { path: 'reservations/seller', element: <ComingSoon /> },
        { path: 'reservations/insider', element: <ComingSoon /> },
        { path: 'reservations/tickets', element: <ComingSoon /> },
        { path: 'reservations/history', element: <ComingSoon /> },
        { path: 'sales', element: <ComingSoon /> },
        {
          path: 'history',
          element: (
            <RequireRol rol="partner" exclude="editor">
              <History />
            </RequireRol>
          ),
        },
      ],
    },
    {
      path: 'check-in/:event',
      element: (
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <RequireAuth validateChangePass>
            <CheckinLayout />
          </RequireAuth>
        </ErrorBoundary>
      ),
      children: [
        {
          path: 'attendees',
          element: (
            <RequireParentEvent>
              <CheckinAttendees />
            </RequireParentEvent>
          ),
        },
        {
          path: 'records',
          element: (
            <RequireParentEvent>
              <CheckinRecords />
            </RequireParentEvent>
          ),
        },
        {
          path: 'checkpoints',
          element: (
            <RequireParentEvent>
              <Checkpoints />
            </RequireParentEvent>
          ),
        },
      ],
    },
    {
      path: PATH_TC,
      element: <TermsAndConditionsPage />,
    },
    {
      path: PATH_PP,
      element: <PrivacyPolicy />,
    },
    {
      path: RESEND_CANCEL,
      element: <ResendCancellation />,
    },
    {
      path: '*',
      element: (
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <LogoOnlyLayout />
        </ErrorBoundary>
      ),
      children: [
        { path: '403', element: <Forbidden /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// AUTHENTICATION
const Login = Loadable(() => import('../pages/auth/Login'));
const Register = Loadable(() => import('../pages/auth/Register'));
const RegisterWithIncode = Loadable(() =>
  import('../pages/auth/RegisterWithIncode'),
);
const ResetPassword = Loadable(() => import('../pages/auth/ResetPassword'));
const ResetWeakPassword = Loadable(() =>
  import('../pages/auth/ResetWeakPassword'),
);
const NewPassword = Loadable(() => import('../pages/auth/NewPassword'));
const ChangePassword = Loadable(() => import('../pages/auth/ChangePassword'));
const VerifyEmailPage = Loadable(() => import('../pages/auth/VerifyEmailPage'));

// Test
const Maintenance = Loadable(() => import('../pages/Maintenance'));
const ComingSoon = Loadable(() => import('../pages/ComingSoon'));
const NotFound = Loadable(() => import('../pages/Page404'));
const Forbidden = Loadable(() => import('../pages/Page403'));
const ResendCancellation = Loadable(() => import('../pages/ResendCancelPage'));

// Dashboard
const EventsPage = Loadable(() => import('../modules/events/pages/Events'));
const EventCreate = Loadable(() =>
  import('../modules/events/pages/EventCreate'),
);
const Billboards = Loadable(() =>
  import('../modules/billboards/pages/Billboards'),
);
const BillboardCreate = Loadable(() =>
  import('../modules/billboards/pages/BillboardCreate'),
);
const Billboard = Loadable(() =>
  import('../modules/billboards/pages/Billboard'),
);

// Campaigns
const Campaigns = Loadable(() => import('../modules/adtech/pages/Campaigns'));
const CreateCampaign = Loadable(() =>
  import('../modules/adtech/pages/CreateCampaign'),
);
const CreateAd = Loadable(() => import('../modules/adtech/pages/CreateAd'));
const AddBalance = Loadable(() => import('../modules/adtech/pages/AddBalance'));
const EditCampaign = Loadable(() =>
  import('../modules/adtech/pages/EditCampaign'),
);
const EditAd = Loadable(() => import('../modules/adtech/pages/EditAd'));

// Events
const Dashboard = Loadable(() =>
  import('../modules/dashboard/pages/Dashboard'),
);

const EventDashboardLayout = Loadable(() =>
  import('../modules/dashboard/pages/EventDashboardLayout'),
);

const Sales = Loadable(() => import('../modules/dashboard/pages/Sales'));

const Clients = Loadable(() => import('../modules/dashboard/pages/Clients'));

const Funnel = Loadable(() => import('../modules/dashboard/pages/Funnel'));

const EventEdit = Loadable(() => import('../modules/events/pages/EventEdit'));
const Payments = Loadable(() => import('../modules/events/pages/Payments'));
const Duplicate = Loadable(() =>
  import('../modules/duplicate/pages/Duplicate'),
);
const Promotions = Loadable(() =>
  import('../modules/promotions/pages/Promotions'),
);
const Tickets = Loadable(() => import('../modules/tickets/pages/Tickets'));
const TicketCreate = Loadable(() =>
  import('../modules/tickets/pages/TicketCreate'),
);
const TicketEdit = Loadable(() =>
  import('../modules/tickets/pages/TicketEdit'),
);
const TicketsMapSelection = Loadable(() =>
  import('../modules/tickets/pages/TicketsMapSelection'),
);
const Map = Loadable(() => import('../modules/tickets/pages/Map'));
const Holds = Loadable(() => import('../modules/holds/pages/Holds'));
const HoldsCreate = Loadable(() =>
  import('../modules/holds/pages/HoldsCreate'),
);
const HoldsView = Loadable(() => import('../modules/holds/pages/HoldsView'));
const HoldsEdit = Loadable(() => import('../modules/holds/pages/HoldsEdit'));
const BatchCreate = Loadable(() =>
  import('../modules/holds/pages/BatchCreate'),
);
const Batch = Loadable(() => import('../modules/holds/pages/Batch'));

const RsvpDashboard = Loadable(() => import('../modules/rspv/pages/Dashboard'));

const RsvpLinks = Loadable(() => import('../modules/rspv/pages/Links'));

const Rspv = Loadable(() => import('../modules/rspv/pages/Rspv'));
const Checkin = Loadable(() => import('../modules/checkin/pages/Checkin'));
const Services = Loadable(() => import('../modules/services/pages/Services'));
const History = Loadable(() => import('../modules/events/pages/History'));
const Surveys = Loadable(() => import('../modules/surveys/pages/Surveys'));

// Reservations
const TotalReservation = Loadable(() =>
  import('../modules/reservations/pages/TotalReservation'),
);
const Booking = Loadable(() => import('../modules/reservations/pages/Booking'));
const Invitations = Loadable(() =>
  import('../modules/invitations/pages/Invitations'),
);

// Terms and Conditions
const TermsAndConditionsPage = Loadable(() =>
  import('../pages/TermsAndConditionsPage'),
);

// Privacy Policy
const PrivacyPolicy = Loadable(() => import('../pages/PrivacyPolicyPage'));

// Team
const MyTeam = Loadable(() => import('../modules/team/pages/MyTeam'));
const MyTeaEdit = Loadable(() => import('../modules/team/pages/MyTeamEdit'));
const MyTeamAdd = Loadable(() => import('../modules/team/pages/MyTeamAdd'));

// Statistics
const Statistics = Loadable(() =>
  import('../modules/statistics/pages/Statistics'),
);
const Statistics2 = Loadable(() =>
  import('../modules/statistics-v2/pages/Statistics2'),
);
const GlobalStatistics = Loadable(() =>
  import('../modules/statistics/pages/GlobalStatistics'),
);

// Super Admin
const Unarchive = Loadable(() =>
  import('../modules/superadmin/pages/Unarchive'),
);
const SuperAdminSearch = Loadable(() =>
  import('../modules/superadmin/pages/Search'),
);
const DeleteAccounts = Loadable(() =>
  import('../modules/superadmin/pages/DeleteAccounts'),
);
const SetPassword = Loadable(() =>
  import('../modules/superadmin/pages/SetPassword'),
);

const ChangeOwnership = Loadable(() =>
  import('../modules/superadmin/pages/ChangeOwnership'),
);

const Bines = Loadable(() => import('../modules/superadmin/pages/Bines'));
const Bank = Loadable(() => import('../modules/superadmin/pages/Bank'));
const TicketAccess = Loadable(() =>
  import('../modules/superadmin/pages/Wallet'),
);
const ActiveCampaigns = Loadable(() =>
  import('../modules/superadmin/pages/ActiveCampaigns'),
);
const CampaignReview = Loadable(() =>
  import('../modules/superadmin/pages/CampaignReview'),
);

// Check In
const Checkpoints = Loadable(() =>
  import('../modules/checkpoints/pages/Checkpoints'),
);

const CheckinEvents = Loadable(() =>
  import('../modules/checkin/pages/CheckinEvents'),
);
const CheckinAttendees = Loadable(() =>
  import('../modules/checkin/pages/CheckinAttendees'),
);
const CheckinRecords = Loadable(() =>
  import('../modules/checkin/pages/CheckinRecords'),
);
